import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  USER_GROUP_MEMBER_ROLE,
  UserGroupMemberRole,
} from 'src/constants/userGroup'
import {
  DashboardsSummary,
  PermissionSummary,
  UserGroupSummary,
} from 'src/features/dashboard/constants/dashboards'
import { BalanceProvider } from 'src/features/dashboard/hooks/useBalance'
import useDashboardUrl from 'src/features/dashboard/hooks/useDashboardUrl'
import useApi from 'src/hooks/useApi'
import useUser from 'src/hooks/useUser'

interface DashboardHeader {
  title: string
  subtitle?: string
}

export interface DashboardState {
  loading: boolean
  getViewPermissions: () => void
  viewableDashboards: PermissionSummary[] | null
  activeGroup: UserGroupSummary | null | undefined
  activeDashboard: DashboardsSummary | null | undefined
  activePermission: PermissionSummary | null | undefined
  requireTwoFactorSetup: boolean | null
  isAdmin: boolean
  isTestnet: boolean
  setIsTestnet: (value: boolean) => void
  header: DashboardHeader | null
  setHeader: Dispatch<SetStateAction<DashboardHeader | null>>
}

const DashboardContext = createContext<DashboardState>({} as DashboardState)

export function DashboardProvider({
  children,
  isAdmin,
}: {
  children: ReactElement
  isAdmin: boolean
}) {
  const user = useUser()
  const [loading, setLoading] = useState<boolean>(true)
  const [isTestnet, setIsTestnet] = useState<boolean>(false)
  const [header, setHeader] = useState<DashboardHeader | null>(null)
  const { url } = useDashboardUrl()

  const [viewableDashboards, setViewableDashboards] =
    useState<PermissionSummary[] | null>(null)

  const { fetch: fetchDashboards } = useApi<PermissionSummary[]>({
    serviceName: 'vault',
    path: 'userGroups/teamReporting',
  })

  const { activeDashboard, activeGroup, activePermission } = useMemo(() => {
    if (viewableDashboards == null || url.userGroupMemberId == null) {
      return {
        activeDashboard: null,
        activeGroup: null,
        activePermission: null,
      }
    }

    const userGroupMembers = viewableDashboards.flatMap(
      (permission) => permission.userGroup.userGroupMembers,
    )

    const dashboard = userGroupMembers.find(
      (member) => member.id === url.userGroupMemberId,
    )

    const group = viewableDashboards.find((permission) =>
      permission.userGroup.userGroupMembers.some(
        (member) => member.id === url.userGroupMemberId,
      ),
    )?.userGroup

    const permission = viewableDashboards.find((permission) =>
      permission.userGroup.userGroupMembers.some(
        (member) => member.id === url.userGroupMemberId,
      ),
    )

    return {
      activeDashboard: dashboard,
      activeGroup: group,
      activePermission: permission,
    }
  }, [viewableDashboards, url.userGroupMemberId])

  const requireTwoFactorSetup: boolean | null = useMemo(() => {
    const hasTwoFactor = user.hasAuthPasskey || user.hasOneTimePasswordCode

    // if the active dashboard is not set, check if all dashboards require 2FA
    if (activeDashboard == null) {
      // If there are not available dashboards, do not opine
      if (viewableDashboards == null) {
        return null
      }

      const require2fa = viewableDashboards.every(
        (dashboard) => dashboard.userGroup.require2fa === true,
      )

      return require2fa && !hasTwoFactor
    }

    return activeGroup?.require2fa === true
      ? !user.hasAuthPasskey && !user.hasOneTimePasswordCode
      : false
  }, [activeGroup, user, activeDashboard, viewableDashboards])

  const getViewPermissions = useCallback(async () => {
    setLoading(true)
    const viewableDashboards = await fetchDashboards()

    setViewableDashboards(viewableDashboards.data || null)
    setLoading(false)
    /**
     * Anytime the 2fa state changes, we need to re-fetch the permissions, but the permission
     * state is not handled directly in this hook, thus its value is not updated.
     *
     * Other deps like setViewableDashboards and setLoading are local useState dispatches
     * and thus do not trigger a re-render of useEffect
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDashboards, requireTwoFactorSetup])

  const administratorDashboards = useMemo(
    () =>
      filterDashboardsByRole(
        viewableDashboards,
        USER_GROUP_MEMBER_ROLE.ADMINISTRATOR,
      ),
    [viewableDashboards],
  )
  const memberDashboards = useMemo(
    () =>
      filterDashboardsByRole(viewableDashboards, USER_GROUP_MEMBER_ROLE.MEMBER),
    [viewableDashboards],
  )

  useEffect(() => {
    if (loading === true && viewableDashboards === null) {
      void getViewPermissions()
    }
  }, [loading, viewableDashboards, getViewPermissions])

  return (
    <DashboardContext.Provider
      value={{
        loading,
        viewableDashboards: isAdmin
          ? administratorDashboards
          : memberDashboards,
        activeGroup,
        activePermission,
        getViewPermissions,
        header,
        setHeader,
        activeDashboard,
        requireTwoFactorSetup,
        isAdmin,
        isTestnet,
        setIsTestnet,
      }}
    >
      <BalanceProvider
        requireTwoFactorSetup={requireTwoFactorSetup}
        viewableDashboards={viewableDashboards}
        activeDashboard={activeDashboard}
      >
        {children}
      </BalanceProvider>
    </DashboardContext.Provider>
  )
}

export default function useDashboard() {
  return useContext(DashboardContext)
}

/**
 * Filters dashboards based on user group member role. Sometimes a user
 * can have multiple roles (such as an admin that is viewing their overview dashboard
 * as a vault owner).
 *
 * The same dashboards do not to be queried multiple times to check permissions, and
 * this function helps aggregate ALL allowed dashboards for a user into the context
 * where they are viewed.
 *
 * @param dashboards - The original list of dashboards to filter.
 * @param role - The acting role to filter by.
 * @returns Filtered list of dashboards or null if input is null.
 */
function filterDashboardsByRole(
  dashboards: PermissionSummary[] | null,
  role: UserGroupMemberRole,
): PermissionSummary[] | null {
  if (!dashboards) {
    return null
  }

  const deviceGroupKey =
    role === USER_GROUP_MEMBER_ROLE.ADMINISTRATOR
      ? 'adminDeviceGroups'
      : 'viewerDeviceGroups'

  return dashboards
    .map((dashboard) => ({
      ...dashboard,
      userGroup: {
        ...dashboard.userGroup,
        userGroupMembers: dashboard.userGroup.userGroupMembers.filter(
          (member) =>
            member.user[deviceGroupKey] != null &&
            member.user[deviceGroupKey].length > 0,
        ),
      },
    }))
    .filter((dashboard) => dashboard.userGroup.userGroupMembers.length > 0)
}
