import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

/**
 * This hook manages URL parameters for the dashboard, enabling deep linking
 * to specific views. This allows for bookmarking, sharing, and refreshing
 * specific dashboard states, maintaining a consistent user experience.
 *
 * The URL parameters include:
 * - userGroupMemberId: Identifies the user group
 * - deviceGroupId: Identifies the selected device group (vault or pay key)
 * - walletAccountId: Identifies a specific wallet account within a device group
 * - walletGroupId: Identifies a group of wallets (used for pay keys)
 * - start and size: Used for pagination of transaction lists
 */

const QUERY_PARAMS = {
  userGroupMemberId: 'userGroupMemberId',
  deviceGroupId: 'deviceGroupId',
  walletAccountId: 'walletAccountId',
  walletGroupId: 'walletGroupId',
  coinType: 'coinType',
  start: 'start',
  size: 'size',
} as const

type StringQueryValue = string | undefined
type NumberQueryValue = number | undefined

export type DashboardUrl = {
  [K in keyof typeof QUERY_PARAMS]: K extends 'start' | 'size'
    ? NumberQueryValue | null
    : StringQueryValue | null
}

/**
 * Hook to manage dashboard URL parameters.
 * @returns An object with the current URL state and setters for each parameter.
 */
export default function useDashboardUrl() {
  const [query, setQuery] = useQueryParams({
    [QUERY_PARAMS.userGroupMemberId]: StringParam,
    [QUERY_PARAMS.deviceGroupId]: StringParam,
    [QUERY_PARAMS.walletAccountId]: StringParam,
    [QUERY_PARAMS.walletGroupId]: StringParam,
    [QUERY_PARAMS.coinType]: StringParam,
    [QUERY_PARAMS.start]: NumberParam,
    [QUERY_PARAMS.size]: NumberParam,
  })

  const url: DashboardUrl = Object.fromEntries(
    Object.entries(QUERY_PARAMS).map(([key, value]) => [key, query[value]]),
  ) as DashboardUrl

  const setUrl = Object.fromEntries(
    Object.entries(QUERY_PARAMS).map(([key, value]) => [
      key,
      (newValue: StringQueryValue | NumberQueryValue) =>
        setQuery({ [value]: newValue }),
    ]),
  ) as { [K in keyof DashboardUrl]: (value: DashboardUrl[K]) => void }

  return { url, setUrl }
}

/**
 * Generates a unique cache key based on the current URL parameters.
 * This is used to invalidate caches when the dashboard view changes.
 * @param url - The current dashboard URL state
 * @returns A string that can be used as a cache key
 */
export function getCacheKey(url: Partial<DashboardUrl>) {
  return Object.values(url).join('-')
}
