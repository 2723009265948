import { css } from '@emotion/react'
import { createContext, useContext } from 'react'

import Card from 'src/components/Card'
import { DotsLoader } from 'src/components/Loaders'
import Spacer from 'src/components/Spacer'
import { TextH1, TextH2 } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { BREAKPOINT, MIN_MEDIA } from 'src/constants/media-query'
import useDocumentTitle from 'src/hooks/useDocumentTitle'

export type PageLayoutType = 'dashboard-page' | 'full-form-page' | 'full-page'

const PageLayoutContext = createContext<PageLayoutType>('full-page')

type BreakpointValueMap = Record<BREAKPOINT, { top: number; bottom: number }>

const LAYOUT_VERTICAL_PADDING: Record<PageLayoutType, BreakpointValueMap> = {
  'full-page': {
    [BREAKPOINT.$480]: { top: 0, bottom: 0 },
    [BREAKPOINT.$800]: { top: 0, bottom: 0 },
    [BREAKPOINT.$1080]: { top: 0, bottom: 0 },
  },
  'full-form-page': {
    [BREAKPOINT.$480]: { top: 32, bottom: 100 },
    [BREAKPOINT.$800]: { top: 48, bottom: 100 },
    [BREAKPOINT.$1080]: { top: 64, bottom: 128 },
  },
  'dashboard-page': {
    [BREAKPOINT.$480]: { top: 24, bottom: 100 },
    [BREAKPOINT.$800]: { top: 32, bottom: 100 },
    [BREAKPOINT.$1080]: { top: 48, bottom: 128 },
  },
}

interface PageProps {
  documentTitle: string
  background?: string
  children?: React.ReactElement | React.ReactNode[]
  loading?: boolean
  layout: PageLayoutType
}

export default function Page({
  documentTitle,
  background,
  children,
  loading,
  layout,
}: PageProps): React.ReactElement {
  useDocumentTitle(documentTitle)

  const verticalPadding = LAYOUT_VERTICAL_PADDING[layout]

  return (
    <PageLayoutContext.Provider value={layout}>
      <div
        data-testid='page'
        css={[
          css`
            width: 100%;
            height: 100%;
            background: ${background ?? COLOR.GRAY_50};

            padding: ${verticalPadding[BREAKPOINT.$480].top}px 0
              ${verticalPadding[BREAKPOINT.$480].bottom}px;
            ${MIN_MEDIA.$800} {
              padding: ${verticalPadding[BREAKPOINT.$800].top}px 0
                ${verticalPadding[BREAKPOINT.$800].bottom}px;
            }
            ${MIN_MEDIA.$1080} {
              padding: ${verticalPadding[BREAKPOINT.$1080].top}px 0
                ${verticalPadding[BREAKPOINT.$1080].bottom}px;
            }
          `,
          layout === 'full-page' &&
            css`
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              overflow: hidden;
            `,
          layout === 'dashboard-page' &&
            css`
              min-width: fit-content;
            `,
        ]}
      >
        {loading === true ? (
          <div
            css={css`
              margin-top: 60px;
            `}
          >
            <DotsLoader />
          </div>
        ) : (
          children
        )}
      </div>
    </PageLayoutContext.Provider>
  )
}

interface PageSectionProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  size: 'narrow' | 'wide' | 'full'
}

export function PageSection({
  size,
  ...props
}: PageSectionProps): React.ReactElement {
  const pageLayout = useContext(PageLayoutContext)

  const horizontalPadding = {
    narrow: {
      [BREAKPOINT.$480]: 24,
      [BREAKPOINT.$800]: 24,
      [BREAKPOINT.$1080]: 20,
    },
    wide: {
      [BREAKPOINT.$480]: 24,
      [BREAKPOINT.$800]: 36,
      [BREAKPOINT.$1080]: 60,
    },
    full: {
      [BREAKPOINT.$480]: 24,
      [BREAKPOINT.$800]: 40,
      [BREAKPOINT.$1080]: 60,
    },
  }[size]

  const maxWidth = {
    narrow: {
      [BREAKPOINT.$480]: `${520 + horizontalPadding[BREAKPOINT.$480] * 2}px`,
      [BREAKPOINT.$800]: `${520 + horizontalPadding[BREAKPOINT.$800] * 2}px`,
      [BREAKPOINT.$1080]: `${520 + horizontalPadding[BREAKPOINT.$1080] * 2}px`,
    },
    wide: {
      [BREAKPOINT.$480]: '1440px',
      [BREAKPOINT.$800]: '1440px',
      [BREAKPOINT.$1080]: '1440px',
    },
    full: {
      [BREAKPOINT.$480]: '100%',
      [BREAKPOINT.$800]: '100%',
      [BREAKPOINT.$1080]: '100%',
    },
  }[size]

  const minWidth = {
    narrow: {
      [BREAKPOINT.$480]: `${300 + horizontalPadding[BREAKPOINT.$480] * 2}px`,
      [BREAKPOINT.$800]: `${300 + horizontalPadding[BREAKPOINT.$800] * 2}px`,
      [BREAKPOINT.$1080]: `${300 + horizontalPadding[BREAKPOINT.$1080] * 2}px`,
    },
    wide: {
      [BREAKPOINT.$480]: '0',
      [BREAKPOINT.$800]: '0',
      [BREAKPOINT.$1080]: '0',
    },
    full: {
      [BREAKPOINT.$480]: '0',
      [BREAKPOINT.$800]: '0',
      [BREAKPOINT.$1080]: '0',
    },
  }[size]

  const margin = {
    narrow: '0 auto',
    wide: pageLayout === 'dashboard-page' ? '0 auto' : '0 auto',
    full: '0 auto',
  }[size]

  return (
    <div
      {...props}
      css={css`
        margin: ${margin};

        max-width: ${maxWidth[BREAKPOINT.$480]};
        min-width: ${minWidth[BREAKPOINT.$480]};
        padding: 0 ${horizontalPadding[BREAKPOINT.$480]}px;

        ${MIN_MEDIA.$800} {
          max-width: ${maxWidth[BREAKPOINT.$800]};
          min-width: ${minWidth[BREAKPOINT.$800]};
          padding: 0 ${horizontalPadding[BREAKPOINT.$800]}px;
        }

        ${MIN_MEDIA.$1080} {
          max-width: ${maxWidth[BREAKPOINT.$1080]};
          min-width: ${minWidth[BREAKPOINT.$1080]};
          padding: 0 ${horizontalPadding[BREAKPOINT.$1080]}px;
        }
      `}
    />
  )
}

export function PageTitleRow(
  props: React.HTMLProps<HTMLDivElement>,
): React.ReactElement {
  return (
    <div
      {...props}
      css={css`
        display: grid;
        grid-gap: 12px;

        ${MIN_MEDIA.$800} {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      `}
    />
  )
}

export function PageTitle(
  props: React.ComponentProps<typeof TextH1>,
): React.ReactElement {
  return (
    <TextH1
      {...props}
      css={css`
        font-size: 32px;
        ${MIN_MEDIA.$800} {
          font-size: 36px;
        }
        ${MIN_MEDIA.$800} {
          font-size: 40px;
        }
      `}
    />
  )
}

export function PageTitleSpacer(): React.ReactElement {
  return (
    <Spacer
      unit={{
        [BREAKPOINT.$480]: 8,
        [BREAKPOINT.$800]: 12,
        [BREAKPOINT.$1080]: 12,
      }}
    />
  )
}

export function PageCards({
  children,
  vertical,
  center,
  cardSize,
}: {
  children: React.ReactElement | React.ReactElement[]
  vertical?: boolean
  center?: boolean
  cardSize?: '360px' | '480px'
}): React.ReactElement {
  const width = cardSize ?? '360px'

  return (
    <div
      css={[
        css`
          display: grid;
          gap: 40px;
        `,
        center === true
          ? css`
              justify-content: center;
              grid-template-columns: repeat(auto-fit, minmax(auto, ${width}));
            `
          : css`
              grid-template-columns: repeat(auto-fill, minmax(auto, ${width}));
            `,
        vertical === true &&
          css`
            grid-template-columns: minmax(auto, ${width});
          `,
      ]}
    >
      {children}
    </div>
  )
}

export function PageCard({
  children,
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'as'> & {
  children: React.ReactElement | React.ReactNode[]
}): React.ReactElement {
  return (
    <Card
      css={css`
        display: flex;
        flex-direction: column;
      `}
      {...props}
    >
      {children}
    </Card>
  )
}

export function PageCardTitle(
  props: React.ComponentProps<typeof TextH2>,
): React.ReactElement {
  return (
    <>
      <TextH2 {...props} />
      <Spacer unit={5} />
    </>
  )
}
