import CoinIconBtc from 'src/images/coin-icon-btc.png';
import CoinIconEth from 'src/images/coin-icon-eth.png';
import CoinIconStakedEth from 'src/images/coin-icon-steth.png';
import CoinIconUsdc from 'src/images/coin-icon-usdc.png';
import CoinIconUsdt from 'src/images/coin-icon-usdt.png'; /**
 * This file serves as a crucial mapping between vault-api and mega-web dashboard properties
 * to facilitate simple in-component logic based on coin types, testnets, and i18n
 * of hard-coded label strings describing vaults.
 */ /**
* The "Summary" types (e.g., PermissionSummary, UserGroupSummary, DeviceGroupSummary, DashboardsSummary)
* are designed to map between API responses and component props, providing a consistent interface
* for data handling across the application.
*/ /**
* Summarizes dashboard information for a user, including their email and associated device groups.
*/ /**
* Defines display labels for different types of vaults and wallets.
* These are used for consistent naming across the application.
*/
const DISPLAY_LABELS = { KEY_SHIELD: 'Key Shield', ETH_SHIELD: 'Eth Shield', BASIC_MULTISIG: 'Basic Multisig', ETH_BASIC_MULTISIG: 'Eth Basic Multisig', SINGLE_KEY: 'Single Key', PHONE: 'Phone' }; /**
* Maps display labels to their corresponding tokens.
* These tokens are used for translation and identification purposes.
*/
const DISPLAY_LABEL_TOKENS = { KEY_SHIELD: 'btc_vault_5_key', ETH_SHIELD: 'eth_vault_5_key', BASIC_MULTISIG: 'btc_vault_3_key', ETH_BASIC_MULTISIG: 'eth_vault_3_key', SINGLE_KEY: 'single_key', PHONE: 'pay_key' }; /**
* Defines supported blockchain networks.
*/
export const NETWORKS = { BTC: 'BTC', ETH: 'ETH' }; /**
* Defines the display properties for different device groups.
*/ /**
* Maps display labels to their corresponding display properties.
* This allows for easy access to network and vault information based on the display label.
*/
export const deviceGroupDisplay = { [DISPLAY_LABELS.KEY_SHIELD]: { isVault: true, networks: [NETWORKS.BTC], labelToken: DISPLAY_LABEL_TOKENS.KEY_SHIELD }, [DISPLAY_LABELS.ETH_SHIELD]: { isVault: true, networks: [NETWORKS.ETH], labelToken: DISPLAY_LABEL_TOKENS.ETH_SHIELD }, [DISPLAY_LABELS.BASIC_MULTISIG]: { isVault: true, networks: [NETWORKS.BTC], labelToken: DISPLAY_LABEL_TOKENS.BASIC_MULTISIG }, [DISPLAY_LABELS.ETH_BASIC_MULTISIG]: { isVault: true, networks: [NETWORKS.ETH], labelToken: DISPLAY_LABEL_TOKENS.ETH_BASIC_MULTISIG }, [DISPLAY_LABELS.SINGLE_KEY]: { isVault: false, networks: [NETWORKS.ETH], labelToken: DISPLAY_LABEL_TOKENS.SINGLE_KEY }, [DISPLAY_LABELS.PHONE]: { isVault: false, networks: [NETWORKS.BTC, NETWORKS.ETH], labelToken: DISPLAY_LABEL_TOKENS.PHONE } }; /**
* Defines the supported coin types for wallet accounts.
* Includes both mainnet and testnet versions of BTC and ETH.
*/
export const WALLET_ACCOUNT_COIN_TYPE = { BITCOIN: 'BTC', BITCOIN_TEST: 'TBTC', ETHEREUM: 'ETH', ETHEREUM_TEST: 'TETH', ETHEREUM_CONTRACT: 'ETH-C', ETHEREUM_CONTRACT_TEST: 'TETH-C', USDC: 'USDC', USDT: 'USDT', STAKED_ETH: 'stETH' }; /**
* List of testnet coin types for easy identification.
*/
export const TEST_COIN_TYPES = [WALLET_ACCOUNT_COIN_TYPE.BITCOIN_TEST, WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_TEST, WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT_TEST]; /**
* Maps coin types to their corresponding pay wallet label tokens.
* These tokens are used for translation and identification of pay wallets.
*/
const PAY_WALLET_LABEL_TOKEN = { [WALLET_ACCOUNT_COIN_TYPE.BITCOIN]: 'btc_pay_wallet', [WALLET_ACCOUNT_COIN_TYPE.BITCOIN_TEST]: 'tbtc_pay_wallet', [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM]: 'eth_pay_wallet', [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_TEST]: 'teth_pay_wallet', [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT]: 'eth_c_pay_wallet', [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT_TEST]: 'teth_c_pay_wallet' }; /**
* Defines the display properties for different wallet account types.
*/ /**
* Maps wallet account coin types to their corresponding display properties.
* This allows for easy access to pay wallet labels, icons, and testnet status based on the coin type.
*/
export const walletAccountDisplay = { [WALLET_ACCOUNT_COIN_TYPE.BITCOIN]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.BITCOIN], iconUrl: CoinIconBtc, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.BITCOIN_TEST]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.BITCOIN_TEST], iconUrl: CoinIconBtc, isTestnet: true }, [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM], iconUrl: CoinIconEth, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.USDC]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM], iconUrl: CoinIconUsdc, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.USDT]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM], iconUrl: CoinIconUsdt, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.STAKED_ETH]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM], iconUrl: CoinIconStakedEth, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_TEST]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_TEST], iconUrl: CoinIconEth, isTestnet: true }, [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT], iconUrl: CoinIconEth, isTestnet: false }, [WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT_TEST]: { payWalletLabelToken: PAY_WALLET_LABEL_TOKEN[WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_CONTRACT_TEST], iconUrl: CoinIconEth, isTestnet: true } };
