import BigNumber from 'bignumber.js';
import { WALLET_ACCOUNT_COIN_TYPE, TEST_COIN_TYPES } from 'src/features/dashboard/constants/dashboards'; /**
 * The order of magnitude difference between satoshis and BTC.
 * 1 BTC = 10^8 satoshis
 * @see https://en.bitcoin.it/wiki/Satoshi_(unit)
 */
const SATOSHI_TO_BTC_MAGNITUDE = 8;
const SATOSHIS_PER_BTC = new BigNumber(10).pow(SATOSHI_TO_BTC_MAGNITUDE); /**
* Converts satoshi to BTC with 8 decimal places.
* @param satoshi - The amount in satoshi.
* @returns A string representation of the BTC amount.
*/
export function formatSatoshiToBTC(satoshi) { const satoshiBN = new BigNumber(satoshi); if (satoshiBN.isNaN() || satoshiBN.isZero()) {
    return '0';
} return truncateTrailingZeros(satoshiBN.dividedBy(SATOSHIS_PER_BTC).toFixed(SATOSHI_TO_BTC_MAGNITUDE)); }
const ETH_DECIMALS = 18;
const STABLECOIN_DECIMALS = 6; /**
* Converts Wei to token
* @param wei - The amount in Wei.
* @returns A string representation of the ETH amount.
*/
export function formatWeiToToken(wei, decimals) { const weiBN = new BigNumber(wei); if (weiBN.isNaN() || weiBN.isZero()) {
    return '0';
} const weiPerToken = new BigNumber(10).pow(decimals); return truncateTrailingZeros(weiBN.dividedBy(weiPerToken).toFixed(decimals)); } /**
* Truncates trailing zeros from a number string.
* @param value - The number string to truncate.
* @returns A string with trailing zeros removed.
*/
export function truncateTrailingZeros(value) { return value.replace(/\.?0+$/, ''); }
export function convertRawBalance(rawBalance, coinType) { switch (coinType) {
    case WALLET_ACCOUNT_COIN_TYPE.BITCOIN:
    case WALLET_ACCOUNT_COIN_TYPE.BITCOIN_TEST: return formatSatoshiToBTC(rawBalance);
    case WALLET_ACCOUNT_COIN_TYPE.ETHEREUM:
    case WALLET_ACCOUNT_COIN_TYPE.ETHEREUM_TEST:
    case WALLET_ACCOUNT_COIN_TYPE.STAKED_ETH: return formatWeiToToken(rawBalance, ETH_DECIMALS);
    case WALLET_ACCOUNT_COIN_TYPE.USDC:
    case WALLET_ACCOUNT_COIN_TYPE.USDT: return formatWeiToToken(rawBalance, STABLECOIN_DECIMALS);
    default: return rawBalance.toString();
} } /**
* Checks if a given coin type is a testnet coin.
* @param coinType - The coin type to check.
* @returns True if the coin type is a testnet coin, false otherwise.
*/
export function isTestnetCoin(coinType) { return TEST_COIN_TYPES.includes(coinType); }
const DISPLAY_MAX_DECIMALS = 8;
export function formatAmountString(amount) { let addPlusSign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false; const [value, unit] = amount.split(' '); const numericValue = parseFloat(value); if (isNaN(numericValue)) {
    return amount;
} const formattedValue = numericValue.toFixed(DISPLAY_MAX_DECIMALS); const trimmedValue = parseFloat(formattedValue).toString(); const result = addPlusSign && numericValue > 0 ? "+".concat(trimmedValue) : trimmedValue; return unit != null ? "".concat(result, " ").concat(unit) : result; } /**
* Interface for a wallet account balance summary.
*/ /**
* Interface for a device group balance summary.
*/ /**
* Interface for the overall balance summary.
*/
