import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react'

import {
  DashboardsSummary,
  PermissionSummary,
  WalletAccountCoinType,
} from 'src/features/dashboard/constants/dashboards'
import { Keyset, Wallet } from 'src/features/dashboard/constants/wallets'
import {
  BalanceSummary,
  convertRawBalance,
  DeviceGroupBalanceSummary,
  formatAmountString,
  isTestnetCoin,
} from 'src/features/dashboard/utils/balanceUtils'
import useApi from 'src/hooks/useApi'
import { useQueue, QueueItem } from 'src/hooks/useQueue'
import { useFormatCurrency } from 'src/hooks/useTranslate'

/**
 * The number of concurrent device group balance summaries to fetch,
 * sufficient for 5-key, 3-key for BTC and ETH, and Pay Wallet
 */
const BALANCE_PARALLELIZATION = 5

/**
 * @property {BalanceSummary} balanceSummary - The balance summaries for all device groups.
 * @property {boolean} isLoading - Indicates if balance data is currently being fetched.
 * @property {(deviceGroupId: string) => number} getDeviceGroupProgress - Retrieve progress as a fraction between 0 and 1.
 * @property {(deviceGroupId: string) => boolean} isDeviceGroupComplete - Retrieve completion status for a device group.
 */
interface BalanceState {
  balanceSummary: BalanceSummary
  isLoading: boolean
  getDeviceGroupProgress: (deviceGroupId: string) => number
  isDeviceGroupComplete: (deviceGroupId: string) => boolean
}

const BalanceContext = createContext<BalanceState | undefined>(undefined)

interface BalanceSummaryPayload {
  userGroupMemberId: string
  deviceGroupId: string
}

/**
 * Formats a single wallet's balance data, including any token balances.
 * @param wallet - The wallet data to format.
 * @param formatCurrency - Function to format currency values.
 * @returns Array of formatted wallet balance summaries (main balance and token balances).
 */
function formatWalletBalance(
  wallet: Wallet,
  formatCurrency: (value: number) => string,
): {
  id: string
  label: string
  coinType: WalletAccountCoinType
  tokenBalance: string
  fiatBalance: string
  isSubAccount: boolean
  isTestnet: boolean
}[] {
  const isTestnetWallet = isTestnetCoin(wallet.coinType)
  const formattedBalances = []

  // Format main wallet balance
  const rawTokenBalance = Number(wallet.balance?.amount) ?? 0
  const tokenBalance = convertRawBalance(rawTokenBalance, wallet.coinType)
  const fiatBalance =
    wallet.balance?.fiatDetail?.[wallet.coinType]?.['usd'] ??
    Number(wallet.balance?.fiat?.['usd'] ?? '0')

  formattedBalances.push({
    id: wallet.id,
    label: wallet.label,
    coinType: wallet.coinType,
    tokenBalance: formatAmountString(`${tokenBalance} ${wallet.coinType}`),
    fiatBalance: formatCurrency(Number(fiatBalance)),
    isSubAccount: Boolean(wallet.parentWalletId),
    isTestnet: isTestnetWallet,
  })

  // Format token balances
  if (wallet.balance?.tokens) {
    Object.entries(wallet.balance.tokens).forEach(
      ([tokenType, tokenAmount]) => {
        const rawTokenBalance = Number(tokenAmount) ?? 0
        const tokenBalance = convertRawBalance(
          rawTokenBalance,
          tokenType as WalletAccountCoinType,
        )

        // Don't add token balances that are 0
        if (tokenBalance === '0') {
          return
        }

        // ERC20s individual fiatValues are in fiatDetail if available
        const tokenFiat = wallet.balance?.fiatDetail?.[tokenType]?.['usd']

        formattedBalances.push({
          id: wallet.id,
          label: `${wallet.label} ${tokenType}`,
          coinType: tokenType as WalletAccountCoinType,
          tokenBalance: formatAmountString(`${tokenBalance} ${tokenType}`),
          fiatBalance:
            tokenFiat == null ? null : formatCurrency(Number(tokenFiat)),
          isSubAccount: true,
          isTestnet: isTestnetCoin(tokenType as WalletAccountCoinType),
        })
      },
    )
  }

  return formattedBalances
}

/**
 * Provides balance information for all device groups across dashboards.
 *
 * This component uses a queue system to optimize balance fetching, which is typically
 * a slow operation. The queue allows for:
 * 1. Parallel fetching of multiple balances (up to BALANCE_PARALLELIZATION)
 * 2. Fetching balances for dashboards that are not yet visible, improving perceived performance
 * 3. Minimizing API calls by caching results and avoiding redundant requests
 *
 * @param {PermissionSummary[] | null} viewableDashboards - List of dashboards the user can view.
 * @param {DashboardsSummary | null | undefined} activeDashboard - The currently active dashboard.
 */
export function BalanceProvider({
  children,
  viewableDashboards,
  activeDashboard,
  requireTwoFactorSetup,
}: {
  children: ReactNode
  viewableDashboards: PermissionSummary[] | null
  activeDashboard: DashboardsSummary | null | undefined
  requireTwoFactorSetup: boolean | null
}) {
  const formatCurrency = useFormatCurrency()
  const [balanceSummary, setBalanceSummary] = useState<BalanceSummary>({})

  const { fetch: getBalanceSummary } = useApi<Keyset>({
    serviceName: 'vault',
    path: 'userGroupMembers/{userGroupMemberId}/deviceGroups/{deviceGroupId}/balanceSummary',
    method: 'GET',
  })

  const fetchBalanceSummary = useCallback(
    async ({
      deviceGroupId,
      userGroupMemberId,
    }: {
      deviceGroupId: string
      userGroupMemberId: string
    }): Promise<DeviceGroupBalanceSummary> => {
      if (balanceSummary[deviceGroupId] != null) {
        return balanceSummary[deviceGroupId] as DeviceGroupBalanceSummary
      }
      const rawBalance = await getBalanceSummary({
        pathData: { userGroupMemberId, deviceGroupId },
      })

      const keyset = rawBalance.data

      if (keyset?.wallets == null) {
        throw new Error('Failed to fetch balance summary')
      }

      const formattedBalance: DeviceGroupBalanceSummary = keyset.wallets.reduce(
        (acc, wallet) => {
          const formattedWallets = formatWalletBalance(wallet, formatCurrency)

          formattedWallets.forEach((formattedWallet) => {
            const isTestnetWallet = isTestnetCoin(formattedWallet.coinType)

            if (
              formattedWallet.isSubAccount === false &&
              wallet.groupBalance != null
            ) {
              const rawGroupBalanceValue =
                Number(wallet.groupBalance.amount) ?? 0
              const groupBalanceValue = convertRawBalance(
                rawGroupBalanceValue,
                wallet.coinType,
              )
              const groupBalanceFiat = Number(
                wallet.groupBalance?.fiat?.['usd'] ?? '0',
              )

              const formattedGroupFiat = formatCurrency(groupBalanceFiat)
              const formattedGroupToken = formatAmountString(
                `${groupBalanceValue} ${wallet.coinType}`,
              )

              acc[isTestnetWallet ? 'testnetBalance' : 'mainnetBalance'] = {
                token: formattedGroupToken,
                fiat: formattedGroupFiat,
              }
            }

            if (acc.walletGroups[wallet.groupingId] != null) {
              acc.walletGroups[wallet.groupingId].push(formattedWallet)
            } else {
              acc.walletGroups[wallet.groupingId] = [formattedWallet]
            }
          })

          return acc
        },
        { walletGroups: {} } as DeviceGroupBalanceSummary,
      )

      setBalanceSummary((prev) => ({
        ...prev,
        [deviceGroupId]: formattedBalance,
      }))

      return formattedBalance
    },
    [getBalanceSummary, balanceSummary, formatCurrency],
  )

  const { addToQueue, start, stop, progress, isItemComplete, isLoading } =
    useQueue<BalanceSummaryPayload, DeviceGroupBalanceSummary>({
      action: fetchBalanceSummary,
      parallelization: BALANCE_PARALLELIZATION,
    })

  useEffect(() => {
    if (requireTwoFactorSetup !== false) {
      return
    }

    const queueItems = generateQueueItems(viewableDashboards)
    addToQueue(...queueItems)
    start()

    return () => {
      stop()
    }
  }, [
    viewableDashboards,
    activeDashboard,
    addToQueue,
    start,
    stop,
    requireTwoFactorSetup,
  ])

  const getDeviceGroupProgress = useCallback(
    (deviceGroupId: string) => {
      return progress[deviceGroupId]
    },
    [progress],
  )

  return (
    <BalanceContext.Provider
      value={{
        isLoading,
        balanceSummary,
        getDeviceGroupProgress,
        isDeviceGroupComplete: isItemComplete,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}

/**
 * Generates queue items for balance fetching based on viewable dashboards.
 * @param viewableDashboards - List of dashboards the user can view.
 * @returns Array of queue items for balance fetching.
 */
function generateQueueItems(
  viewableDashboards: PermissionSummary[] | null,
): QueueItem<BalanceSummaryPayload, DeviceGroupBalanceSummary>[] {
  if (!viewableDashboards) {
    return []
  }

  const allDashboards = viewableDashboards.flatMap(
    (dashboard) => dashboard.userGroup.userGroupMembers,
  )

  const queueItems: QueueItem<
    BalanceSummaryPayload,
    DeviceGroupBalanceSummary
  >[] = []

  for (const dashboard of allDashboards) {
    const viewerDeviceGroupIds =
      dashboard.user.viewerDeviceGroups?.map((deviceGroup) => deviceGroup.id) ??
      []

    const adminDeviceGroupIds =
      dashboard.user.adminDeviceGroups?.map((deviceGroup) => deviceGroup.id) ??
      []

    const uniqueDeviceGroupIds = [
      ...new Set([...viewerDeviceGroupIds, ...adminDeviceGroupIds]),
    ]

    for (const deviceGroupId of uniqueDeviceGroupIds) {
      queueItems.push({
        id: deviceGroupId,
        payload: {
          userGroupMemberId: dashboard.id,
          deviceGroupId,
        },
      })
    }
  }

  return queueItems
}

/**
 * Hook to access balance information for all device groups.
 *
 * This hook provides access to the balance data fetched and managed by the BalanceProvider.
 * It leverages a queue system for efficient, parallel fetching of balance data, which is
 * particularly beneficial for slow balance fetching and formatting operations.
 *
 * Key benefits of the queue system:
 * 1. Parallel fetching: Multiple balance requests are processed concurrently.
 * 2. Preemptive loading: Balances for non-visible dashboards are fetched in advance.
 * 3. Optimized API usage: Caching and avoiding redundant requests minimize API calls.
 * 4. Progressive loading: Provides progress information for each device group.
 *
 * @returns The balance context containing summaries, loading state, and progress information.
 * @throws {Error} If used outside of a BalanceProvider.
 */
export function useBalance(): BalanceState {
  const context = useContext(BalanceContext)
  if (context === undefined) {
    throw new Error('useBalance must be used within a BalanceProvider')
  }
  return context
}
