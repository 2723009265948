import { css } from '@emotion/react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import {
  AnimatedCtaContent,
  AnimatedMainContent,
} from 'src/components/AnimatedContent'
import {
  ExternalLinkAsButton,
  InternalLinkAsButton,
} from 'src/components/LinkAsButton'
import Page, { PageSection } from 'src/components/Page'
import Spacer from 'src/components/Spacer'
import { TextH1 } from 'src/components/Text'
import COLOR from 'src/constants/color'
import LINK from 'src/constants/link'
import ROUTE from 'src/constants/route'
import useTranslate from 'src/hooks/useTranslate'

const buttonStyle = css`
  width: 100%;
`

export default function AppErrorPage(): React.ReactElement {
  const { translate, translateToString } = useTranslate()
  const matchSubscribePlan = useRouteMatch(ROUTE.SUBSCRIBE_PLAN)
  const location =
    useLocation<undefined | { fromLocation?: ReturnType<typeof useLocation> }>()

  const buttonProps = {
    size: 'medium',
    variant: 'secondary',
    css: buttonStyle,
  } as const

  return (
    <Page
      documentTitle={translateToString('unknown_error')}
      layout='full-page'
      background={COLOR.BLACK}
    >
      <AnimatedMainContent visible={true} centered={true}>
        <TextH1
          id='app-error-icon'
          data-testid='app-error-icon'
          css={css`
            font-size: 48px;
          `}
        >
          {/* eslint-disable-next-line react/jsx-no-literals */}
          {'🔦'}
        </TextH1>
        <Spacer unit={8} />
        <TextH1
          id='app-error-message'
          data-testid='app-error-message'
          css={css`
            font-size: 48px;
            color: ${COLOR.WHITE};
          `}
        >
          {translate('something_wrong')}
        </TextH1>
        <Spacer unit={12} />
        <AnimatedCtaContent>
          <PageSection size='narrow'>
            {location.state?.fromLocation != null ? (
              <InternalLinkAsButton
                {...buttonProps}
                id='go-back-link-button'
                data-testid='go-back-link-button'
                to={location.state?.fromLocation}
              >
                {translate('go_back')}
              </InternalLinkAsButton>
            ) : matchSubscribePlan != null ? (
              <ExternalLinkAsButton
                {...buttonProps}
                id='pricing-link-button'
                data-testid='pricing-link-button'
                href={LINK.WEB_PRICING}
                openInSameTab={true}
              >
                {translate('go_back_to_pricing')}
              </ExternalLinkAsButton>
            ) : (
              <InternalLinkAsButton
                {...buttonProps}
                to={ROUTE.HOME}
                id='home-link-button'
                data-testid='home-link-button'
              >
                {translate('go_back_to_dashboard')}
              </InternalLinkAsButton>
            )}
          </PageSection>
        </AnimatedCtaContent>
      </AnimatedMainContent>
    </Page>
  )
}
