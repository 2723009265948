import { efwAsync } from '@casa/common/src/lib/errorFirstWrap'
import { useState, useCallback, useEffect, useRef } from 'react'

// Default configuration values
const DEFAULT_RETRY_LIMIT = 3
const DEFAULT_PARALLELIZATION = 1
const DEFAULT_ITEM_TIME_ESTIMATE_MS = 1000
const DEFAULT_BACKOFF_RANGE = 0.2
const DEFAULT_BASE_PROGRESS = 0.1
const DEFAULT_PROGRESS_UPDATE_INTERVAL_MS = 100

/**
 * Represents an item in the queue.
 * @template T The type of the payload.
 * @template R The type of the result.
 */
export interface QueueItem<T, R> {
  id: string
  payload: T
  retries?: number
  startTime?: number
  endTime?: number
  result?: R
  error?: Error
}

/**
 * Configuration options for the queue.
 * @template T The type of the payload.
 * @template R The type of the result.
 */
interface QueueOptions<T, R> {
  /** The action to perform on each queue item. */
  action: (payload: T) => Promise<R>
  /** Maximum number of retry attempts for failed items. */
  retryLimit?: number
  /** Number of items to process concurrently. */
  parallelization?: number
  /** Initial estimate of time (in ms) to process an item. */
  itemTimeEstimate?: number
  /** Range for progress backoff when an item takes longer than estimated. */
  backoffRange?: number
  /** Initial progress value for newly added items. */
  baseProgress?: number
  /** Interval (in ms) for updating progress. */
  progressUpdateIntervalMs?: number
}

/**
 * Internal state of the queue.
 * @template T The type of the payload.
 * @template R The type of the result.
 */
interface QueueState<T, R> {
  queue: QueueItem<T, R>[]
  inProgress: QueueItem<T, R>[]
  completed: QueueItem<T, R>[]
  failed: QueueItem<T, R>[]
  isLoading: boolean
}

/**
 * Internal state for individual queue items.
 */
interface ItemState {
  progress: number
  isComplete: boolean
  startTime?: number
}

/**
 * A custom hook for managing a queue of asynchronous operations with features
 * for progress reporting, retries, and concurrency control.
 *
 * @template T The type of the payload for each queue item.
 * @template R The type of the result for each queue item.
 *
 * @param options Configuration options for the queue.
 * @returns An object with methods to control the queue and monitor its progress.
 */
export function useQueue<T, R>({
  retryLimit = DEFAULT_RETRY_LIMIT,
  parallelization = DEFAULT_PARALLELIZATION,
  itemTimeEstimate = DEFAULT_ITEM_TIME_ESTIMATE_MS,
  backoffRange = DEFAULT_BACKOFF_RANGE,
  baseProgress = DEFAULT_BASE_PROGRESS,
  progressUpdateIntervalMs = DEFAULT_PROGRESS_UPDATE_INTERVAL_MS,
  action,
}: QueueOptions<T, R>) {
  const [state, setState] = useState<QueueState<T, R>>({
    queue: [],
    inProgress: [],
    completed: [],
    failed: [],
    isLoading: false,
  })

  const [isRunning, setIsRunning] = useState(false)
  const [progress, setProgress] = useState<Record<string, number>>({})
  const [averageItemTime, setAverageItemTime] = useState(itemTimeEstimate)
  const itemStatesRef = useRef<Map<string, ItemState>>(new Map())

  const calculateProgress = useCallback(
    (startTime: number, now: number) => {
      const elapsedTime = now - startTime
      if (elapsedTime < averageItemTime) {
        // Linear progress calculation for items within expected time
        return (
          baseProgress +
          (1 - baseProgress - backoffRange) * (elapsedTime / averageItemTime)
        )
      }

      // Logarithmic slowdown for items taking longer than expected
      const overTime = elapsedTime - averageItemTime
      const maxProgress = 1 - backoffRange
      const backoffProgress =
        maxProgress * (1 - 1 / (1 + Math.log1p(overTime / averageItemTime)))
      return maxProgress + backoffProgress * backoffRange
    },
    [averageItemTime, baseProgress, backoffRange],
  )

  const updateItemState = useCallback(
    (itemId: string, update: Partial<ItemState>) => {
      const currentState = itemStatesRef.current.get(itemId) || {
        progress: baseProgress,
        isComplete: false,
      }

      itemStatesRef.current.set(itemId, { ...currentState, ...update })
    },
    [baseProgress],
  )

  const addToQueue = useCallback(
    (...items: QueueItem<T, R>[]) => {
      setState((prev) => ({
        ...prev,
        queue: [
          ...prev.queue,
          ...items.map((item) => ({ ...item, retries: 0 })),
        ],
      }))
      items.forEach((item) => {
        updateItemState(item.id, { progress: baseProgress, isComplete: false })
      })
    },
    [updateItemState, baseProgress],
  )

  const start = useCallback(() => setIsRunning(true), [])
  const stop = useCallback(() => setIsRunning(false), [])

  const processQueue = useCallback(async () => {
    if (!isRunning || state.inProgress.length >= parallelization) {
      return
    }

    const item = state.queue[0]
    if (item == null) {
      return
    }

    // Move item from queue to inProgress
    setState((prev) => ({
      ...prev,
      // Remove the first item from the queue
      queue: prev.queue.slice(1),
      // Add item to inProgress with start time
      inProgress: [...prev.inProgress, { ...item, startTime: Date.now() }],
    }))

    updateItemState(item.id, {
      progress: baseProgress,
      isComplete: false,
      startTime: Date.now(),
    })

    const [error, result] = await efwAsync(action(item.payload))
    const endTime = Date.now()

    if (error) {
      if ((item.retries ?? 0) < retryLimit) {
        // Move item back to queue for retry
        setState((prev) => ({
          ...prev,
          // Remove item from inProgress
          inProgress: prev.inProgress.filter((i) => i.id !== item.id),
          // Add item back to queue with increased retry count
          queue: [...prev.queue, { ...item, retries: (item.retries ?? 0) + 1 }],
        }))
        updateItemState(item.id, { progress: baseProgress, isComplete: false })
      } else {
        // Move item to failed list
        setState((prev) => ({
          ...prev,
          // Remove item from inProgress
          inProgress: prev.inProgress.filter((i) => i.id !== item.id),
          // Add item to failed list with error
          failed: [...prev.failed, { ...item, error }],
        }))
        updateItemState(item.id, { progress: 1, isComplete: true })
      }
    } else {
      // Move item to completed list
      setState((prev) => ({
        ...prev,
        // Remove item from inProgress
        inProgress: prev.inProgress.filter((i) => i.id !== item.id),
        // Add item to completed list with result and end time
        completed: [...prev.completed, { ...item, result, endTime }],
      }))
      updateItemState(item.id, { progress: 1, isComplete: true })

      // Update average item time
      setAverageItemTime((prev) => {
        const itemTime = endTime - (item.startTime ?? endTime)
        return (prev + itemTime) / 2 // Calculate new average
      })
    }
  }, [
    isRunning,
    state,
    parallelization,
    retryLimit,
    action,
    updateItemState,
    baseProgress,
  ])

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(processQueue, progressUpdateIntervalMs)
      return () => clearInterval(interval)
    }
  }, [isRunning, processQueue, progressUpdateIntervalMs])

  useEffect(() => {
    const interval = setInterval(() => {
      state.inProgress.forEach((item) => {
        if (item.startTime != null) {
          const progress = calculateProgress(item.startTime, Date.now())
          updateItemState(item.id, { progress })
        }
      })
    }, progressUpdateIntervalMs)

    return () => clearInterval(interval)
  }, [
    state.inProgress,
    calculateProgress,
    updateItemState,
    progressUpdateIntervalMs,
  ])

  const getItemProgress = useCallback(
    (itemId: string) => {
      return itemStatesRef.current.get(itemId)?.progress ?? baseProgress
    },
    [baseProgress],
  )

  const isItemComplete = useCallback((itemId: string) => {
    return itemStatesRef.current.get(itemId)?.isComplete ?? false
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newProgress: Record<string, number> = {}
      state.queue.forEach((item) => {
        newProgress[item.id] = getItemProgress(item.id)
      })
      state.inProgress.forEach((item) => {
        newProgress[item.id] = getItemProgress(item.id)
      })
      state.completed.forEach((item) => {
        newProgress[item.id] = 1
      })
      state.failed.forEach((item) => {
        newProgress[item.id] = 1
      })
      setProgress(newProgress)
    }, progressUpdateIntervalMs)

    return () => clearInterval(intervalId)
  }, [state, getItemProgress, progressUpdateIntervalMs])

  return {
    addToQueue,
    start,
    stop,
    isItemComplete,
    progress,
    isLoading: state.inProgress.length > 0,
  }
}

/**
 * Example usage:
 *
 * const { addToQueue, start, stop, progress, isLoading } = useQueue({
 *   action: async (payload: string) => {
 *     // Your async operation here
 *     await someAsyncOperation(payload);
 *     return 'result';
 *   },
 *   parallelization: 3,
 *   retryLimit: 2,
 * });
 *
 * // Add items to the queue
 * addToQueue({ id: '1', payload: 'item1' }, { id: '2', payload: 'item2' });
 *
 * // Start processing
 * start();
 *
 * // Monitor progress
 * console.log(progress);
 *
 * // Stop processing when done
 * if (!isLoading) {
 *   stop();
 * }
 */
