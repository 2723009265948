/**
 * Similar to node's util.callbackify, but with explicit returns for error-first callback result
 * Intended to clean up "last mile" try/catch blocks and protect against unhandled promise rejections
 */
export function efw(callable) {
    try {
        const result = callable();
        return [undefined, result];
    }
    catch (error) {
        return [error, undefined];
    }
}
export async function efwAsync(callable) {
    try {
        const result = await callable;
        return [undefined, result];
    }
    catch (error) {
        return [error, undefined];
    }
}
